<template>
    <main id="home">
        <NavBar />
        <div id="banner">
            <div id="banner_text">
                <h1>Jacopo Folgoni Borsa</h1>
                <h2>Computer Science Student</h2>
                <div id="banner_icons">
                    <a target="_blank" href="https://github.com/JacopoFB">
                        <GithubIcon />
                    </a>
                    <a target="_blank" href="www.linkedin.com/in/jacopo-folgoni-borsa-079161252">
                        <LinkedInIcon />
                    </a>                    
                    <a href="mailto:jacopofb03@gmail.com">
                        <EmailIcon />
                    </a>
                </div>
            </div>
        </div>

        <AboutSection id="about" />
        <ResumeSection id="resume" />
        <ProjectSection id="projects" />
    </main>
</template>

<script>
import NavBar from "@/components/sections/NavBar.vue";
import AboutSection from "@/components/sections/AboutSection.vue";
import ResumeSection from "@/components/sections/ResumeSection.vue";
import ProjectSection from "@/components/sections/ProjectSection.vue";

import GithubIcon from "@/components/icons/GithubIcon.vue";
import LinkedInIcon from "@/components/icons/LinkedInIcon.vue";
import EmailIcon from "@/components/icons/EmailIcon.vue";

export default {
    components: {
        LinkedInIcon,
        GithubIcon,
        EmailIcon,
        NavBar,
        AboutSection,
        ResumeSection,
        ProjectSection
    }
}
</script>

<style scoped>

@import "../assets/css/icons.css";

@media screen and (max-width: 750px) {
    #banner_text h1 {
        font-size: 40px !important;
    }
}

main {
    background-color: rgb(45, 45, 48);
    width: 100vw;
    max-width: 100vw;
}

#banner {
    background-color: rgb(34, 34, 34);
    height: 100vh;
}
#banner_text {
    position: relative;
    top: 40%;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
#banner_text h1 {
    font-size: 70px;
}
#banner_text h2 {
    font-weight: normal;
    font-size: 22px;
    margin: 30px;
    letter-spacing: 2.5px;
}
#banner_icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#banner_icons a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px !important;
}

</style>