<template>
    <div id="navbar">
        <div class="links nav_links">
            <a href="#home">Home</a>
            <a href="#about">About</a>
            <a href="#resume">Resume</a>
            <a href="#projects">Projects</a>
        </div>

        <!-- Burger menu (mobile) -->
        <div class="links nav_links_burger" ref="burger_links">
            <a href="#home" @click="toggleBurgerMenu()">Home</a>
            <a href="#about" @click="toggleBurgerMenu()">About</a>
            <a href="#resume" @click="toggleBurgerMenu()">Resume</a>
            <a href="#projects" @click="toggleBurgerMenu()">Projects</a>
        </div>
            
        <svg
            v-if="!burgerOpen"
            xmlns="http://www.w3.org/2000/svg" 
            class="burger_menu" 
            width="40" height="40" 
            viewBox="0 0 24 24" 
            stroke-width="2" 
            stroke="currentColor" 
            fill="none" 
            stroke-linecap="round" 
            stroke-linejoin="round"
            @click="toggleBurgerMenu()"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 6l16 0" />
            <path d="M4 12l16 0" />
            <path d="M4 18l16 0" />
        </svg>
        <svg 
            v-else
            xmlns="http://www.w3.org/2000/svg" 
            class="burger_menu" 
            width="44" height="44" 
            viewBox="0 0 24 24" 
            stroke-width="1.5" 
            stroke="currentColor" 
            fill="none" 
            stroke-linecap="round" 
            stroke-linejoin="round"
            @click="toggleBurgerMenu()"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
        </svg>
    </div>
    
    
</template>

<script>

export default {
    data() {
        return {
            burgerOpen: false,
        }
    },  
    methods: {
        toggleBurgerMenu() {
            const links = this.$refs.burger_links;

            if (this.burgerOpen) {
                links.style.display = 'none';
            } else {
                links.style.display = 'flex';
            }
            
            this.burgerOpen = !this.burgerOpen;
        },
    }
}

</script>

<style scoped>
@media screen and (max-width: 550px) {
    .burger_menu {
        display: flex !important;
    }
    #navbar {
        flex-direction: column !important;
    }
    .nav_links {
        display: none !important;
    }  
    .nav_links_burger {
        display: flex;
    }
}
@media screen and (min-width: 551px) {
    .nav_links_burger {
        display: none !important;
    }
    .links a:hover {
        color: #5a92e1;
        transition: 0.25s;
    }
}

#navbar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgb(212, 212, 212);
    background-color: rgb(45, 45, 48);
    z-index: 99;
}
.nav_links {
    display: flex;
}
.nav_links_burger {
    display: none;
    flex-direction: column;
}

.links a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #d5d5d5;
    font-size: 18px;
    text-decoration: none;
    padding: 30px;
    letter-spacing: 2.5px;
}


.burger_menu {
    display: none;
    padding: 20px;
    text-align: right;
    margin: 0 auto;
}
.burger_menu:hover {
    cursor: pointer;
    stroke-width: 2.5px;
}
</style>