<template>
    <div class="section_container">
        <h1 class="section_title">RESUME</h1>

        <div class="interactions">
            <a href="/resources/resume.pdf" download>Download as PDF</a>
            <p>|</p>
            <a href="/resources/resume.pdf" target="_blank">Preview as PDF</a>
        </div>
        
        <div class="resume_container">
            <a href="/resources/resume.pdf" target="_blank"></a>
            <img src="/images/resume.png" class="resume" />
        </div>
    </div>
</template>

<style scoped>

@import "../../assets/css/titles.css";

.section_title {
    padding-top: 100px;
    padding-bottom: 50px;
    
}
.section_container {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1200px;

    display: flex;
    flex-direction: column;
}
.resume_container {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}
.resume_container > a {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.resume_container > a:hover {
    background-color: rgba(0, 0, 0, 0.06);
    cursor: pointer;
}
.resume {
    width: 100%;
}

.interactions {
    display: flex;
    flex-direction: row;
    margin: 0 auto 50px auto;
}
.interactions a {
    text-decoration: none;
    padding: 10px;
    font-size: 17px;
    color: #d5d5d5;
    letter-spacing: 1.5px;
}
.interactions a:hover {
    transition: 0.25s;
    color: #5a92e1;
}
.interactions p {
    padding: 10px;
    font-size: 17px;
    color: #d5d5d5;
}

</style>