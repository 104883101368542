<template>
    <HomePage />
</template>

<script>
import HomePage from '@/views/HomePage.vue';

export default {
    name: 'JacopoPortfolio',
	components: {
		HomePage
    }
}
</script>

<style>
* {
	padding: 0;
	margin: 0;
}

#app {
    font-family: Source Sans Pro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #d5d5d5;
	overflow-x: hidden;
}
</style>
