<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        class="icon icon-tabler icon-tabler-mail" 
        :width="size" 
        :height="size" 
        viewBox="0 0 24 24" 
        stroke-width="1.8" 
        stroke="white" 
        fill="none" 
        stroke-linecap="round" 
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path>
        <path d="M3 7l9 6l9 -6"></path>
    </svg>
</template>

<script>

export default {
    props: {
        size: {
            type: Number,
            default: 40 
        }
    }
}

</script>