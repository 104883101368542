<template>
    <div class="section_container">
        <h1 class="section_title">ABOUT</h1>

        <div class="content_container">
            <div class="content_split">
                <img class="profile_pic" src="https://avatars.githubusercontent.com/u/63126840?v=4" />

                <div class="content">
                    <p>
                        Hello! I'm Jacopo, a dedicated full-time Computer Science student at Colorado State University, currently
                        specializing in software engineering. 
                        Throughout my academic journey, my dedication and passion have been the driving forces behind the various projects
                        I have collaborated on. 

                        <br><br>
                        
                        I possess a diverse skill set spanning front-end and back-end web development technologies, as 
                        well as proficiency in lower-level programming languages like Java, C, and C++. 
                        Throughout the years, I've actively contributed to various high-caliber school and side projects, 
                        not only gaining valuable technical experience but also honing my ability to collaborate 
                        effectively within a team.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

@import "../../assets/css/titles.css";

@media screen and (max-width: 1000px) {
    .content_split {
        flex-direction: column;
    }
    .profile_pic {
        margin: 0px auto 50px auto;
    }
}
@media screen and (max-width: 750px) {
    .section_container {
        padding: 125px 5px 10px 5px !important;
    }
    .content p {
        padding: 0px 20px 0px 20px !important;
    }
}
.section_container {
    padding: 100px;
    max-width: 1100px;
    margin: 0 auto;
}
.content_container {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}
.content_split { 
    display: flex;
}
.content_split div {
    display: flex;
    flex-direction: column;
}
.content p {
    font-size: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    padding: 0px 50px 0px 50px;
    line-height: 32px;
}
.profile_pic {
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

</style>