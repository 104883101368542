<template>
    <div class="section_container">
        <h1 class="section_title">PROJECTS</h1>

        <div class="projects_preview_container">
            <div class="project_list">
                <div class="project">
                    <img src="/images/florasynthExample.png" />
                    <div class="project_information">
                        <h3>FloraSynth</h3>
                        <p>
                            A 3D procedural asset generator for trees and nature, powered by Three.js. Users can export their work in various 3D
                            file formats.
                        </p>
                        <div style="flex-grow: 1"></div>
                        <a href="https://www.florasynth.com" target="_blank">Check it out!</a>
                    </div>
                    <div class="project_tags">
                        <p class="t_javascript">JavaScript</p>
                        <p class="t_threejs">Three.js</p>
                        <p class="t_vuejs">Vue.js</p>
                        <p class="t_java">Java</p>
                    </div>
                </div>
                <div class="project">
                    <img src="/images/pychatExample.PNG" />
                    <div class="project_information">
                        <h3>pyChat</h3>
                        <p>
                            A simple, anonymous, and fully encrypted chat application built using sockets in Python 3.
                        </p>
                        <div style="flex-grow: 1"></div>
                        <a href="https://github.com/f0lg0/pyChat" target="_blank">Check it out!</a>
                    </div>
                    <div class="project_tags">
                        <p class="t_python">Python</p>
                    </div>
                </div>
                <div class="project">
                    <img src="/images/devsBunkerExample.png" />
                    <div class="project_information">
                        <h3>devsBunker</h3>
                        <p>
                            A social media platform where developers could promote their work for free. While no longer online,
                            it is well documented on github.
                        </p>
                        <div style="flex-grow: 1"></div>
                        <a href="https://github.com/fb-co/devsbunker" target="_blank">Check it out!</a>
                    </div>
                    <div class="project_tags">
                        <p class="t_javascript">JavaScript</p>
                        <p class="t_nodejs">Node.js</p>
                        <p class="t_vuejs">Vue.js</p>
                        <p class="t_graphql">GraphQL</p>
                        <p class="t_mongodb">MongoDB</p>
                    </div>
                </div>
                <div class="project">
                    <img src="/images/electsumExample.png" />
                    <div class="project_information">
                        <h3>Electsum</h3>
                        <p>
                            An online tool designed to shed insight into campaign finance by delivering transparent and intuitive insights into the vast 
                            network of funding for federal elections campaigns. Developed on a strict deadline in a team of 8 individuals.
                        </p>
                        <div style="flex-grow: 1"></div>
                        <a href="https://electsum.org/" target="_blank">Check it out!</a>
                    </div>
                    <div class="project_tags">
                        <p class="t_javascript">JavaScript</p>
                        <p class="t_react">React</p>
                        <p class="t_sql">SQL</p>
                        <p class="t_python">Python</p>
                    </div>
                </div>
                <div class="project_dummy"></div>
                <div class="project_dummy"></div>
                <div class="project_dummy"></div>
                <div class="project_dummy"></div>
                <div class="project_dummy"></div>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>

@import "../../assets/css/titles.css";
@import "../../assets/css/language_tags.css";

.section_title {
    padding-top: 100px;
}
.section_container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding-bottom: 60px; 
}

.project_list {
    margin-top: 50px;
    width: calc(100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
}
.project {
    width: 500px;
    margin: 5px;
    box-sizing: border-box;
    background-color: rgb(34, 34, 34);
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
}
.project:hover {
    border: 1px solid grey;
    transition: 0.25s;
}

.project img {
    width: 100%;
    height: 50%;
    object-fit: cover;
}
.project_information {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-grow: 1;
}
.project_information h3 {
    text-align: left;
    margin-bottom: 20px;
    font-size: 23px;
    font-weight: bold;
}
.project_information p {
    font-size: 18px;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgb(131, 131, 131);
}
.project_information a {
    text-align: left;
    margin-top: 25px;
    color: rgb(131, 131, 131);
}
.project_information a:hover {
    color: #5a92e1;
}

.project_tags {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.project_tags p {
    padding: 4px 13px 3px 13px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 25px;
    font-size: 12px;
}

.project_dummy {
    height: 0;
    width: 500px;
    margin-left: 5px;
    margin-right: 5px;
}

</style>